<template>
    <div class="modal clickable-modal" tabindex="-1" role="dialog"
        :class="{ 'show': cota.showModalCertificado, 'd-block': cota.showModalCertificado }"
        @click="closeModalCertificado">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div v-if="cota.certificated.isLoading" class="loading modal-body"></div>
                <div class="modal-body">
                    <button type="button" class="btn" @click="closeModalCertificado">
                        <span>&times;</span>
                    </button>
                    <h2 class="text-center titulo-modal">Certificado de plantio </h2>

                    <h5 class="text-center titulo-modal mt-4 mr-4 ml-4 mb-3" style="color: #000; font-size: 16px;">Você
                        plantou <b>{{ cota.certificated?.QuantidadeSemResgate }} </b> árvores {{
                            cota.certificated?.CertificadoEmitidos?.length == 0 ? 'e ainda não gerou certificado de plantio'
                        : '' }} </h5>

                    <div class="row justify-content-center">
                        <a @click="submitCertificado()" class="btn btn-adquirir"
                            style="width: 60%; padding: 10px 1rem 10px 1rem;">Emitir Certificado</a>
                    </div>

                    <div class="m-3">
                        <table class="table" v-if="cota.certificated?.CertificadoEmitidos?.length != 0">
                            <thead>
                                <tr>
                                    <th scope="col">Data da geração</th>
                                    <th scope="col">Árvores</th>
                                    <th scope="col">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in cota.certificated?.CertificadoEmitidos" :key="index">
                                    <td>{{ formatarData(item.DataDeGeracao) }} </td>
                                    <td>{{ item.QuantidadeArvores }} </td>
                                    <td>
                                        <a :href="item.UrlCertificado" target="_blank">Download</a>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example ">
                            <nav aria-label="Page navigation example ">
                                <ul class="pagination justify-content-center mt-3"
                                    v-if="cota.certificated?.CertificadoEmitidos?.length > 5">
                                    <li class="page-item" :class="{ disabled: paginaAtual === 1 }">
                                        <a class="page-link" @click="irParaPagina(paginaAtual - 1)">{{ '<' }} </a>
                                    </li>
                                    <li class="page-item" v-for="pagina in numeroDePaginas" :key="pagina"
                                        :class="{ active: pagina === paginaAtual }">
                                        <a class="page-link" @click="irParaPagina(pagina)">{{ pagina }}</a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: paginaAtual === numeroDePaginas }">
                                        <a class="page-link" @click="irParaPagina(paginaAtual + 1)">{{ '>' }}</a>
                                    </li>
                                </ul>
                            </nav>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCotaStore } from "@/stores/CotaStore";
import { computed } from "vue";

export default {
    setup() {
        const cota = useCotaStore();

        let paginaAtual = 1;
        const itensPorPagina = 5;

        const closeModalCertificado = () => {
            cota.showModalCertificado = false;
            document.body.classList.remove('modal-open');
        };

        const submitCertificado = () => {
            cota.postCertificado();
        }

        const formatarData = (data) => {
            const dataObj = new Date(data);
            const dia = String(dataObj.getDate()).padStart(2, '0');
            const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
            const ano = dataObj.getFullYear();
            return `${dia}/${mes}/${ano}`;
        }

        const irParaPagina = (pagina) => {
            // Ir para a página especificada
            if (pagina >= 1 && pagina <= numeroDePaginas.value) {
                paginaAtual = pagina;
            }
        };

        const numeroDePaginas = computed(() => {
            // Verificar se cota.certificated e cota.certificated?.CertificadoEmitidos não são nulos
            if (cota.certificated && cota.certificated?.CertificadoEmitidos) {
                return Math.ceil(cota.certificated?.CertificadoEmitidos?.length / itensPorPagina);
            } else {
                return 0; // Retorna 0 se a lista de certificados não estiver disponível
            }
        });

        const itensExibidos = computed(() => {
            // Calcular os itens exibidos na página atual
            const inicio = (paginaAtual - 1) * itensPorPagina;
            const fim = inicio + itensPorPagina;
            return cota.certificated?.CertificadoEmitidos.slice(inicio, fim);
        });

        return {
            cota,
            closeModalCertificado,
            submitCertificado,
            formatarData,
            paginaAtual,
            irParaPagina,
            numeroDePaginas,
            itensExibidos
        }
    },

}
</script>